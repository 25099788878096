import Head from 'next/head'
import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Mobilebar from './Mobilebar'
import Topbar from './Topbar'
import { Toaster } from 'react-hot-toast'
import AlertsConsumer from './AlertsConsumer'
import routes from '../config/routes'
import { HeroIcons } from '../lib/utils/heroIcons'
import { useTranslation } from 'next-i18next'
import { NavigationItem } from '../types/Navigation'
import { classNames } from '../lib/utils/classNames'

type LayoutProps = {
  children: React.ReactNode
  topbarComponent?: React.JSX.Element
  showTopbar?: boolean
  customBottomBar?: React.JSX.Element
}

export default function LayoutWithSidebar({
  children,
  topbarComponent,
  showTopbar = true,
  customBottomBar,
}: LayoutProps) {
  const { t } = useTranslation('common')

  const [sidebarState, setSidebarState] = useState(false)

  const [mobilebarState, setMobilebarState] = useState<boolean>(false)
  const [mobilebarOpen, setMobilebarOpen] = useState(false)

  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const navigation: NavigationItem[] = [
    {
      name: t('navigation.home'),
      href: routes.index,
      icon: HeroIcons.HomeIcon,
    },
    {
      name: t('navigation.dashboard'),
      href: routes.dashboard,
      icon: HeroIcons.Squares2X2Icon,
    },
    {
      name: t('navigation.originals'),
      href: routes.properties,
      icon: HeroIcons.BuildingStorefrontIcon,
    },
    {
      name: t('navigation.account'),
      href: routes.account.profile,
      current: routes.account.index,
      icon: HeroIcons.UserIcon,
    },
  ]

  const updateByScreenSize = () => {
    const sidebarBreakpoint = 1280
    const sidebarBreakpointOrLess = window.innerWidth <= sidebarBreakpoint
    if (sidebarBreakpointOrLess) {
      setSidebarState(false)
    }
    const mobilebarBreakpoint = 1024
    const mobilebarBreakpointOrLess = window.innerWidth >= mobilebarBreakpoint
    setMobilebarState(!mobilebarBreakpointOrLess)
  }

  useEffect(() => {
    updateByScreenSize()
    window.addEventListener('resize', updateByScreenSize)
    setIsLoaded(true)
    return () => {
      window.removeEventListener('resize', updateByScreenSize)
    }
  }, [])

  const desktopLayout = () => {
    return (
      <>
        <Toaster
          toastOptions={{
            success: {
              style: {
                border: '1px solid green',
              },
            },
            error: {
              style: {
                border: '1px solid red',
              },
            },
          }}
        />

        <main className="relative flex h-screen">
          <div className="h-full">
            <Sidebar
              setSidebarState={setSidebarState}
              sidebarState={sidebarState}
              navigation={navigation}
            />
          </div>
          <div className="flex w-full flex-col overflow-scroll px-12 pb-5 xl:px-32">
            {showTopbar && <Topbar component={topbarComponent} />}
            {children}
          </div>
        </main>
      </>
    )
  }

  const mobileLayout = () => {
    return (
      <>
        <Toaster
          toastOptions={{
            success: {
              style: {
                border: '1px solid green',
              },
            },
            error: {
              style: {
                border: '1px solid red',
              },
            },
          }}
        />

        <div className="flex h-[100dvh] flex-col">
          {topbarComponent && (
            <div className="border-b px-4 py-3">{topbarComponent}</div>
          )}
          <main className={classNames('flex-grow overflow-y-scroll p-4')}>
            {children}
          </main>
          <div>
            {customBottomBar ? (
              customBottomBar
            ) : (
              <Mobilebar
                navigation={navigation}
                mobilebarOpen={mobilebarOpen}
                setMobilebarOpen={setMobilebarOpen}
              />
            )}
          </div>
        </div>
      </>
    )
  }

  const setLayout = (mobileView: boolean) => {
    if (!isLoaded) {
      return <>{children}</>
    }

    return mobileView ? mobileLayout() : desktopLayout()
  }

  return (
    <>
      <Head>
        <></>
      </Head>
      <AlertsConsumer />
      {setLayout(mobilebarState)}
    </>
  )
}
